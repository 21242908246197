<template>
  <div>
    <ImageGallery />
  </div>
</template>

<script>
import ImageGallery from "../components/Users/imageGallery.vue";
export default {
  components: { ImageGallery },
};
</script>

<style scoped></style>
