<template>
  <div>
    <image-category-details />
  </div>
</template>

<script>
import imageCategoryDetails from "../../components/Users/gallery/imageCategoryDetails.vue";
export default {
  components: { imageCategoryDetails },
};
</script>

<style scoped></style>
