<template>
  <div class="pt-10 mt-5 ">
     
    <ImageTabViews />
   
  </div>
</template>

<script>
import ImageTabViews from "../components/Users/imageTabView.vue";
export default {
  components: { ImageTabViews },
};
</script>

<style></style>
